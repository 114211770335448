<template>
  <div class="app__wrapper">
    <HeaderComponent @toggleSidebar="toggleSidebar" class="navbar" :isSidebarOpen="isSidebarOpen"/>
    <SidebarComponent :isOpen="isSidebarOpen" @toggleSidebar="toggleSidebar" :isHomePage="isHomePage"/>
    <router-view></router-view>
    <GoBack v-if="!isListPage && !isHomePage" class="back" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent.vue";
import GoBack from "./components/JourneyComponent/GoBack/GoBack.vue";
import SidebarComponent from "./components/SidebarComponent/SidebarComponent.vue";
import { useRoute } from "vue-router";
import { RoutingRouteEnum } from "./routing/route.enum";

export default defineComponent({
  components: {
    HeaderComponent,
    GoBack,
    SidebarComponent,
  },
  name: "App",
  setup() {
    const route = useRoute();
    const isSidebarOpen = ref(false);

    const isListPage = computed(() => route.name === RoutingRouteEnum.love);
    const isHomePage = computed(() => route.name === RoutingRouteEnum.home);

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    return {
      isListPage,
      isHomePage,
      isSidebarOpen,
      toggleSidebar,
    };
  },
});
</script>

<style>
body,
html {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

button {
  background-color: #DFF5DC;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  color: #476974;
  font-weight: bolder;
}

#app {
  background-color: #f7fcf9;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: inherit;
  height: inherit;
}

.app__wrapper {
  display: flex;
  margin: 0 8px;
}

    h3 {
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    color: #476974;
  }

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #476974;
  background-color: #dff5dc;
  background-image: linear-gradient(to right bottom, #b1d2c1, #b9d9c6, #c2dfcb, #cae6d0, #d3ecd5, #d8edd6, #ddefd7, #e2f0d8, #e3edd6, #e4e9d4, #e4e6d3, #e4e3d2);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.back {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 20px;
  z-index: 1;
}
</style>
