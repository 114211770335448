<template>
    <div :class="{ [styles.wrapper]: true, [styles.wrapper__list]: isListPage || isBeginningsPage, [styles.reviews]: isReviewsPage }">
        <div @click="goBack">
            <FontAwesomeIcon icon="fa-arrow-left" />
            <span>Back</span>
        </div>
        <div v-if="isListPage" @click="$emit('toggleList')">
            <FontAwesomeIcon icon="fa-list" />
            <span>Toggle list</span>
        </div>
        <div v-if="isBeginningsPage" @click="goToEverything">
            <span>Next</span>
            <FontAwesomeIcon icon="fa-arrow-right" />
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faList, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { RoutingRouteEnum } from '@/routing/route.enum';

library.add(faArrowLeft, faList, faArrowRight);

export default defineComponent({
    components: {
        FontAwesomeIcon,
    },
    name: 'GoBack',
    setup() {
        const router = useRouter();
        const route = useRoute();

        const isListPage = computed(() => route.name === RoutingRouteEnum.love);
        const isBeginningsPage = computed(() => route.name === RoutingRouteEnum.birthday);
        const isReviewsPage = computed(() => route.name === RoutingRouteEnum.reviews);

        const goBack = (): void => {
            router.back();
        };

        const goToEverything = (): void => {
            router.push(RoutingRouteEnum.everything);
        };

        return {
            isListPage,
            isBeginningsPage,
            isReviewsPage,
            goBack,
            goToEverything,
        };
    },
});
</script>
<style module="styles" lang="scss">
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    div {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    &:hover {
        cursor: pointer;
    }

    span {
        color: #2F4858;
    }

    svg {
        color: #88AEA8;
    }

    &__list {
        width: 100%;
        justify-content: space-around;
    }
}
.reviews {
    display: none;
}
</style>
