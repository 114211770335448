<template>
  <div :class="{ [styles.sidebar]: true, [styles['sidebar-open']]: isOpen }">
    <div :class="styles.additional">
      <span> For the sake of organization I guess </span>
    </div>
    <div :class="styles.buttons">
      <button v-for="button in buttons" :key="button.id" @click="handleButtonClick(button)" :class="{ [styles.button__container]: true , [styles.buttons__selected]: button.selected }">
        <FontAwesomeIcon :icon="button.icon" />
        {{ button.text }}
        <FontAwesomeIcon v-if="button.id === 3" icon="fa-caret-down" />
        <div v-if="(showChildRoutes && button.id === 3) || (isEverything && button.id === 3)" :class="styles.dropdown">
          <button v-for="button in everythingButtons" :key="button.id" @click="goToDesignatedRoute(button.route)" :class="{[styles.dropdown__selected]: button.selected}">
            <FontAwesomeIcon :icon="button.icon" />

            <span>{{ button.text }}</span>
          </button>
        </div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { RoutingRouteEnum } from "@/routing/route.enum";
import { computed, defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHome, faWandMagicSparkles, faHeart, faCaretDown, faEnvelope, faMusic, faFaceGrinHearts, faComment } from "@fortawesome/free-solid-svg-icons";

library.add(faHome, faWandMagicSparkles, faHeart, faCaretDown, faEnvelope, faMusic, faFaceGrinHearts, faComment);

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  name: "SidebarComponent",
  props: {
    isOpen: Boolean,
    isHomePage: Boolean,
  },
  setup(props, context) {
    const router = useRouter();
    const route = useRoute();
    const showChildRoutes = ref(false);
    const isHomePage = computed(() => route.name === RoutingRouteEnum.home);
    const isBeginningsPage = computed(() => route.name === RoutingRouteEnum.birthday);
    const isLetterPage = computed(() => route.name === RoutingRouteEnum.letter);
    const isReviewsPage = computed(() => route.name === RoutingRouteEnum.reviews);
    const isLovePage = computed(() => route.name === RoutingRouteEnum.love);
    const isPlaylistPage = computed(() => route.name === RoutingRouteEnum.playlist);
    const isEverythingPage = computed(() => route.name === RoutingRouteEnum.everything);
    const isEverything = computed(() => route.name === RoutingRouteEnum.everything || isLetterPage.value || isLovePage.value || isPlaylistPage.value || isReviewsPage);


    const buttons = ref([
      { id: 1, icon: "fa-home", text: "Home", route: RoutingRouteEnum.home, selected: isHomePage },
      { id: 2, icon: "fa-wand-magic-sparkles", text: "Beginnings", route: RoutingRouteEnum.birthday, selected: isBeginningsPage},
      { id: 3, icon: "fa-heart", text: "Everything", route: RoutingRouteEnum.everything, selected: isEverythingPage},
    ]);

    const everythingButtons = ref([
      { id: 1, icon: "fa-heart", text: "Everything", route: RoutingRouteEnum.everything, selected: isEverythingPage },
      { id: 2, icon: "fa-envelope", text: "Letter", route: RoutingRouteEnum.letter, selected: isLetterPage },
      { id: 3, icon: "fa-face-grin-hearts", text: "What I Love About You", route: RoutingRouteEnum.love, selected: isLovePage },
      { id: 4, icon: "fa-music", text: "Playlist", route: RoutingRouteEnum.playlist, selected: isPlaylistPage },
      { id: 5, icon: "fa-comment", text: "Reviews", route: RoutingRouteEnum.reviews, selected: isReviewsPage },
    ]);

    const goToDesignatedRoute = (route: string): void => {
      router.push(route);
      context.emit("toggleSidebar");
    };

    const handleButtonClick = (button: any): void => {
      if (button.id !== 3) {
        goToDesignatedRoute(button.route);
      } else {
        toggleDropdown();
      }
    };

    const toggleDropdown = (): void => {
      showChildRoutes.value = !showChildRoutes.value;
    };

    return {
      buttons,
      everythingButtons,
      showChildRoutes,
      isEverything,
      handleButtonClick,
      goToDesignatedRoute,
      toggleDropdown,
    };
  },
});
</script>

<style module="styles" lang="scss">
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background-color: #dff5dc;
  background-image: linear-gradient(to right bottom, #b1d2c1, #b9d9c6, #c2dfcb, #cae6d0, #d3ecd5, #d8edd6, #ddefd7, #e2f0d8, #e3edd6, #e4e9d4, #e4e6d3, #e4e3d2);
  color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  z-index: 1000;
  @media (max-width: 500px) {
    width: 280px;
  }
}
.sidebar-open {
  transform: translateX(0);
}
.button__container {
  position: relative;
  width: 100%;
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    width: 90%;
    padding: 15px;
    text-align: start;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    &:hover {
      color: #ABC0A8;
    }
    &:first-child {
      margin-top: 5px;
    }

    svg {
      margin-left: 5px;
    }
  }

  &__selected {
    background-color: #dff5dc !important;
    border: 1px solid #b1d2c1;
  }
}
.additional {
  padding: 10px;
  margin-top: 10px;
  font-size: 12px;
  color: #476974;
}
.dropdown {
  margin: 5px 5px 0 5px;
  padding: 10px;
  background-color: #EEFEEE;
  border-radius: 20px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1999;
  width: -webkit-fill-available;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  button {
    margin-top: 5px;
    width: 100%;
    text-align: left;
  }

  &__selected {
    background-color: #dff5dc !important;
    border: 1px solid #b1d2c1;
  }
}
</style>
