<template>
  <div :class="styles.content">
    <div :class="styles.wrapper">
      <blockquote>
        The handwritten letter will be the "updated" version of this, as with every day that passes, I find more
        and more things to love about you, more feelings that you ignite in me, and more ways that you make my life a
        better experience. And anyway, there's never enough words in the world that could describe how I feel about you,
        so the more the merrier.
      </blockquote>
      <blockquote>
        From the moment that I met you - yes <i>the</i> moment - there was an instant spark of chemistry. I was
        too dumb to appreciate it, but I knew it was there. And I thank all the forces in the universe for finally getting
        me to be where I am now with you.
      </blockquote>
      <blockquote>
        It's crazy to me how everything has always aligned for us to be together. If we were away, we'd reunite. If we're
        together, things work our way. Nothing was ever a coincidence and fuck am I the luckiest person on the planet for
        my fate to be you.
      </blockquote>
      <blockquote>
        The funny thing is, falling in love with you was an experience I never thought possible. Feeling so secure and yet
        so
        confused while wondering "when will this go bad?" almost every step of the way. Thinking this was just too good to
        be true. But it is true.
      </blockquote>
      <blockquote>
        Falling for you was like a percussive beat; steady and consistent - until it wasn't. Steady until it began to feel
        like fire was burning into every ounce of my being. Consistent until my heart began
        to start thumping harder after every moment I spent with you. When I fell for you, I fell hard and my love keeps
        growing to this day, so much so that it becomes difficult to breathe sometimes but in the best way
        imaginable.
      </blockquote>
      <blockquote>
        It was one of the best things that has ever happened to me. It baffles me every time you wonder
        why or how I love you. There's <a href="#" @click="goToLovePage">so much to love about you</a>. And most of all,
        you are so easy to love - it's hard
        <i>not</i> to.
        I am so glad I got the privilege to see everything under that mask of yours. As I've told you, with every layer
        peeled,
        I fell more and more for you.
      </blockquote>
      <blockquote>
        Remember our cliff analogy? When I finally fell in love with you, I did not just hit the ground
        face-first. I hit the ground so hard it dug a hole that gets deeper and deeper with every day, planting more and
        more seeds and roots of love every step of the way. I can never get enough of it. I believe I never will for as
        long as you'll have me.
      </blockquote>
      <blockquote>
        And contrary to what you believe, I am not a patient person, but you bring it out in me. Because as hard as it was
        to
        to reach you and have you let me in, I've been the happiest person alive since I did. And I knew that would be the
        case. You were and are worth every obstacle and every waiting moment.
      </blockquote>
      <blockquote>
        I've mentioned you being my home in another page and before writing this one paragraph, but yesterday you asked me
        out of the blue if I think you're my
        home, so I thought I'd update this and reiterate: you <i>are</i> my home. I've never felt so at peace with someone
        before. You are the best partner anyone could ask for. When I'm
        with you, I can't get enough of you. When I'm without you, I miss you. And there's a reason for that; you are the
        <i>epitome</i> of comfort, peace, and love to me. And I will try my best to always remind you of it so you never
        doubt it
        for another second again.
      </blockquote>
      <blockquote>
        I love you and I am so grateful for your existence. I hope you have
        the happiest of birthdays. If I could have one wish on your behalf, it's that I'll get to spend every remaining
        birthday with you and do the best I can to make each one even happier than the one before. You deserve all of the
        happiness in the world and I'm gonna do the best job that I can to make as much of that possible.
      </blockquote>
    </div>
  </div>
</template>

<script lang="ts">
import { RoutingRouteEnum } from '@/routing/route.enum';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'LetterComponent',
  setup() {
    const router = useRouter();

    const goToLovePage = (): void => {
      router.push(RoutingRouteEnum.love);
    }

    return {
      goToLovePage,
    };
  },
});
</script>

<style module="styles" lang="scss">
.content {
  max-height: 500px;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.wrapper {
  text-align: left;

  blockquote {
    font-size: 14px;

    a {
      text-decoration: none;
      font-style: italic;
      font-weight: bold;
    }
  }
}

button {
  background-color: #dff5dc;
  color: #2F4858;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  margin-top: 30px;

  &:hover {
    cursor: pointer;
  }
}

h1 {
  margin: 8px 0 0;
  color: #2F4858;
  font-family: 'Montserrat', sans-serif;
}</style>
