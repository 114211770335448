<template>
  <div :class="styles.content">
    <div :class="styles.text">
      <span>A playlist of songs I compiled that either remind me of you or I dedicate to you (if you saw this on my spotify already no you didn't)</span>
    </div>
    <iframe style="border-radius:12px"
      src="https://open.spotify.com/embed/playlist/0ulXRbRjUX5LR3RoRBD37E?utm_source=generator&theme=0" width="100%"
      height="352" frameBorder="0"
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PlaylistComponent',
  setup() {
    const something = ref('');

    return {
      something,
    };
  },
});
</script>

<style module="styles" lang="scss">
.content {
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  iframe {
    margin-bottom: 30px;
  }
}
.text {
  text-align: start;
  font-size: 14px;
  border-radius: 20px;
}
button {
  background-color: #dff5dc;
  color: #2F4858;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  margin-top: 30px;

  &:hover {
    cursor: pointer;
  }
}

h2 {
  margin: 30px 0 0;
  color: #2F4858;
  font-family: 'Montserrat', sans-serif;
}

a {
  color: #42b983;
}</style>
