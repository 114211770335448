import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_GoBack = _resolveComponent("GoBack")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_HeaderComponent, {
      onToggleSidebar: _ctx.toggleSidebar,
      class: "navbar",
      isSidebarOpen: _ctx.isSidebarOpen
    }, null, 8, ["onToggleSidebar", "isSidebarOpen"]),
    _createVNode(_component_SidebarComponent, {
      isOpen: _ctx.isSidebarOpen,
      onToggleSidebar: _ctx.toggleSidebar,
      isHomePage: _ctx.isHomePage
    }, null, 8, ["isOpen", "onToggleSidebar", "isHomePage"]),
    _createVNode(_component_router_view),
    (!_ctx.isListPage && !_ctx.isHomePage)
      ? (_openBlock(), _createBlock(_component_GoBack, {
          key: 0,
          class: "back"
        }))
      : _createCommentVNode("", true)
  ]))
}