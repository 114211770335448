<template>
  <div :class="styles.content">
    <img alt="my baby" src="../assets/koala.png">
    <h1>Happiest Birthday to My Koala &#9829;</h1>
    <button @click="startJourney"> <h3>Start Journey</h3></button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { RoutingRouteEnum } from '../routing/route.enum';

export default defineComponent({
  name: 'JumMain',
  setup() {
    const router = useRouter();
    const something = ref('');

    const startJourney = (): void => {
      router.push(RoutingRouteEnum.birthday);
    };

    return {
      something,
      startJourney,
    };
  },
});
</script>

<style module="styles" lang="scss">
.content {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  @media (max-width: 700px) {
    img {
      max-width: 300px;
    }
  }
}

button {
  background-color: #dff5dc;
  color: #2F4858;
  font-weight: bolder;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  margin-top: 30px;

  &:hover {
    cursor: pointer;
  }
}

h1 {
  margin: 30px 0 0;
  color: #476974;
  font-family: 'Montserrat', sans-serif;
}

a {
  color: #42b983;
}</style>
