import { createApp } from "vue";
import App from "./App.vue";
import router from "./routing/routes";

const app = createApp(App);

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${to.meta.title} - Your App Name` : "Default Title - Your App Name";
  next();
});

app.use(router);
app.mount("#app");
