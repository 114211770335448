<template>
  <div :class="styles.content">
    <span>Prepare for some cheesy shit. (And one inside joke).</span>
    <button v-for="button in buttons" :key="button.id"  @click="goToDesignatedRoute(button.route)">
      <h3>{{ button.text }}</h3>
    </button>
  </div>
</template>

<script lang="ts">
import { RoutingRouteEnum } from '../../../routing/route.enum';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'EverythingComponent',
  setup() {
    const router = useRouter();

    const buttons = ref([
      { id: 1, text: "Letter", route: RoutingRouteEnum.letter },
      { id: 2, text: "What I love About You", route: RoutingRouteEnum.love },
      { id: 3, text: "Playlist", route: RoutingRouteEnum.playlist },
      { id: 4, text: "Reviews", route: RoutingRouteEnum.reviews },
    ]);

    const goToDesignatedRoute = (route: string): void => {
      router.push(route);
    };

    return {
      buttons,
      goToDesignatedRoute,
    };
  },
});
</script>

<style module="styles" lang="scss">
.content {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  button {
    width: 300px !important;
  }

  @media (max-width: 395px) {
    margin-block-start: -50px;
  }
}
</style>
