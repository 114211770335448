import { ref } from "vue";

export default function useMessages() {
  const messages = ref([
    { id: 1, text: "I love how silly you get when you're caffeinated." },
    { id: 2, text: "I love your smile. I'll never get tired of saying it: you have the prettiest smile I've ever seen and I mean it." },
    { id: 3, text: "I love the way you make me feel safe." },
    { id: 4, text: "I love the way your eyes twinkle when you laugh." },
    { id: 5, text: "I love how determined you are." },
    { id: 6, text: "I hate the way you bullshit, but I love that it makes you you. I wouldn't have it any other way." },
    { id: 7, text: "I love that you have a youthful spirit. I hope you never let it die." },
    { id: 8, text: "I love how grumpy you are in the morning (when i'm not there)." },
    { id: 9, text: "I love that you taught me the right type of love." },
    { id: 10, text: "I love that I get butterflies every time I'm about to see you or every time you call." },
    { id: 11, text: "I love the way you blush every time I get a wave of more-intense-than-usual affection." },
    { id: 12, text: "I love the way you giggle at tiktoks." },
    { id: 13, text: "I love the way you update me with a bunch of messages whenever you get adrenaline." },
    { id: 14, text: "I love that every time I see something that excites me, you're the first person I want to tell." },
    { id: 15, text: "I love how you challenge me; how we challenge each other." },
    { id: 16, text: "I love that we're on the same wavelength." },
    { id: 17, text: "I love that I can just sit in silence with you and we can do nothing but rot together." },
    { id: 18, text: "I love how you get on my nerves." },
    { id: 19, text: "I love that you make me feel loved." },
    { id: 20, text: "I love that we can be ourselves around each other." },
    { id: 21, text: "I love that you're always there for me and always know just how to react to my stupid moods. You get me in ways very few do." },
    { id: 22, text: "I love that you work so hard and sacrifice so much but I hate that you're too hard on yourself." },
    { id: 23, text: "I love how funny you are. You make me laugh so much. I love every silly moment we spend together." },
    { id: 24, text: "I love that you make me want to be the best version of myself." },
    { id: 25, text: "I love that stupid sound you make when you get excited." },
    { id: 26, text: "I love your baby voice and Adam voice. Don't let anyone tell you otherwise!" },
    { id: 27, text: "I love that you use stupid long intelligent words that nobody else does. It pisses me off, but I love it. Another thing that makes you you." },
    { id: 28, text: "I love how witty and clever you are." },
    { id: 29, text: "I love your empathy." },
    { id: 30, text: "I love that you put so much effort into caring for others and showing them your love for them." },
    { id: 31, text: "I love that you're my missing piece. We complete each other." },
    { id: 32, text: "I love that you don't just tell me you love me, you show me in so many ways." },
    { id: 33, text: "I love how excited you get about the things you're passionate about." },
    { id: 34, text: "I love that you'll always go along with whatever thread of a discussion I start, no matter how weird and hypothetical." },
    { id: 35, text: "I love that you can rock any type of style; bossy, quirky, girly, classy, anything." },
    { id: 36, text: "I love that you get excited about things like outfit themes." },
    { id: 37, text: "I love the adorable little sounds you make when you're sleepy." },
    { id: 38, text: "On that note, I love your cuddles." },
    { id: 39, text: "I love that you take care of me when I've had a bad or stressful day even when you know I don't need it, but you do it anyway." },
    { id: 40, text: "I love that you stand up for what's important." },
    { id: 41, text: "I love that you have a fire in you so bright, it's contagious." },
    { id: 42, text: "I love how courageous you are in all areas of life, no matter how scared you get, you tackle whatever comes your way anyway." },
    { id: 43, text: "I hate that you think you need a mask around most people. I love the person underneath." },
    { id: 44, text: "I love that you gave me the privilege of loving you." },
    { id: 45, text: "I love how you make no sense; like how you care about organized things, planners, and stationary, but have the messiest room in the world." },
    { id: 46, text: "I love how you have these secret little passions that just pop out of nowhere. I can't wait to keep discovering them." },
    { id: 47, text: "I love how creative you are. You have a world of magic and art in you." },
    { id: 48, text: "I love that we understand each other's limits, like when either of us needs alone time." },
    { id: 49, text: "I love that we communicate so well." },
    { id: 50, text: "I love that you never fail to know how to make me smile on a bad day." },
    { id: 51, text: "I love that I never get tired of seeing you, but we're healthy enough to have time apart." },
    { id: 52, text: "I love that both our strengths and weaknesses compliment each other." },
    { id: 53, text: "I love telling you bedtime stories. I love that you like hearing them to sleep. It's that youthful spirit." },
    { id: 54, text: "I love that you're constantly willing to improve on anything even when you're the best at something." },
    { id: 55, text: "I love that you never give up." },
    { id: 56, text: "I love that you're so self-aware and always self-reflect." },
    { id: 57, text: "I love your intelligence. I love learning things from you." },
    { id: 58, text: "And I love that we each get to learn from each other and from each of our differences." },
    { id: 59, text: "I love that you always choose to do the right thing." },
    { id: 60, text: "I love how humble you are. You're almost always the smartest girl in the room, but you don't shove it in people's faces (except mine)." },
    { id: 61, text: "I love how you drive me crazy in every type of way." },
    { id: 62, text: "I love how proud you make me, and I love that you don't do it for me or for anybody else. You do it for you." },
    { id: 63, text: "I love how generous you are and how much you love to spoil people you love." },
    { id: 64, text: "I love that you're my kite and I'm the thread." },
    { id: 65, text: "I love that you make me feel like I'm more than good enough." },
    { id: 66, text: "I love how competitive you are. Comes back to the challenging each other thing." },
    { id: 67, text: "I love that I can talk to you about anything." },
    { id: 68, text: "I love that you always wanna hear about something that interests me, even if it doesn't interest you." },
    { id: 69, text: "I love that we're both introverts but could love doing extrovert-things together." },
    { id: 70, text: "I love how driven you are." },
    { id: 71, text: "I love that you're not only my partner, but you feel like my best friend too." },
    { id: 72, text: "I love how you get when you're fake-mad." },
    { id: 73, text: "I love the sound of your voice when you just wake up in the morning." },
    { id: 74, text: "I love going to sleep next to you and waking up next to you in the morning." },
    { id: 75, text: "I love how well you listen." },
    { id: 76, text: "I love that you have the perfect balance of crazy and chill." },
    { id: 77, text: "I love how you almost always have a unique answer to one of my hypothetical questions." },
    { id: 78, text: "I love that you ask me those hypothetical questions too." },
    { id: 79, text: "I love that you turned my life upside down but so gracefully and positively." },
    { id: 80, text: "I love that you're patient with me if I'm having a bad day." },
    { id: 81, text: "I love how emotionally intelligent you are." },
    { id: 82, text: "I love all the imperfections you hate." },
    { id: 83, text: "I love that you make me relate to the most disgustingly sweet love songs. You make them make sense." },
    { id: 84, text: "I love how much effort you put into the relationship." },
    { id: 85, text: "I love listening to you talk about the things you're passionate about." },
    { id: 86, text: "I love you in doctor mode." },
    { id: 87, text: "I love how charming you are to others even when you're not in a socializing mood (except for when you're sleepy but we'll let that slide)." },
    { id: 88, text: "I love how you look in my clothes. Cheesy but true." },
    { id: 89, text: "I love learning new things about you every day." },
    { id: 90, text: "I love meeting new people in your family or hearing old stories from when you were a kid. Makes me feel closer to you." },
    { id: 91, text: "I love how at peace you make me feel." },
    { id: 92, text: "I love how every time I'm with you, I don't want the day to end. You make every minute worthwhile, even when we're not doing anything." },
    { id: 93, text: "I love that you get me excited to wake up in the morning" },
    { id: 94, text: "I love how curious you are on a bigger scale of things." },
    { id: 95, text: "I love how endearing you are when you're nervous." },
    { id: 96, text: "I love and hate how stubborn you are." },
    { id: 97, text: "I love your singing voice. I love the sound of your voice in all its forms." },
    { id: 98, text: "I love your uneven teeth. Please don't ever get rid of them." },
    { id: 99, text: "I love how you support me. You always know exactly how to." },
    { id: 100, text: "I love that you write your e's from the bottom to the center instead of the other way around like a normal person." },
    { id: 101, text: "I love that you don't just like to give, but you put thought and effort. Instead of a birthday text, you'd handwrite a letter with all the smudge, folds, rawness, and authenticity. You don't just get flowers, you arrange them yourself. You don't just spoil me, you know me enough to know exactly what I need and when." },
    { id: 102, text: "I love that I started this with like 20 things, then 50, then 100, and now adding some more because there's so much to love about you." },
    { id: 103, text: "I love that I can go on and on about the things I love about you, because you're full of so much magic and wonder. I love you for all your quirks and flaws and qualities." },
    { id: 104, text: "I love that you are my fate." },
    { id: 105, text: "And I love that you are my home." },
  ]);

  const pros = ref([
    { id: 1, text: "Smart" },
    { id: 2, text: "Hilarious" },
    { id: 3, text: "Will challenge you" },
    { id: 4, text: "Will take care of you" },
    { id: 5, text: "Will tell you if your breath smells like coffee or garlic" },
    { id: 6, text: "Will have your back" },
    { id: 7, text: "Will bring you snacks on a bad day" },
    { id: 8, text: "Is gorgeous as fuck" },
    { id: 9, text: "Has the prettiest smile" },
    { id: 10, text: "Has the sweetest laugh" },
    { id: 11, text: "Will respond if you tell her you love her while shes asleep" },
    { id: 12, text: "Will do the cutest whining if you move away while cuddling" },
    { id: 13, text: "Will cuddle whenever you want" },
    { id: 14, text: "Will trust you to make the right decisions " },
    { id: 15, text: "Can be trusted to make her own decisions" },
    { id: 16, text: "Will send you tiktoks every day" },
    { id: 17, text: "Is always present in one way or another" },
    { id: 18, text: "Will tell you and do for you the sweetest things " },
    { id: 19, text: "Will fill your heart with so much warmth" },
    { id: 20, text: "Knows exactly how to show her love. You wouldn't believe it's her first time." },
  ]);

  const cons = ref([
    { id: 1, text: "Sleepy eepy weepy 24/7" },
    { id: 1, text: "Has a 12 hour mark" },
    { id: 1, text: "Has the worst taste in food" },
    { id: 1, text: "Won't kiss you if your breath smells like coffee or garlic :(" },
  ]);
  return {
    messages,
    pros,
    cons,
  };
}
