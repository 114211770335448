<template>
  <div :class="styles.content">
    <div :class="styles.card">
      <span>Customer Reviews of Jum</span>
      <div :class="styles.card__content">
        <div :class="styles.stars">
          <span>
            <FontAwesomeIcon icon="fa-star" />
            <FontAwesomeIcon icon="fa-star" />
            <FontAwesomeIcon icon="fa-star" />
            <FontAwesomeIcon icon="fa-star" />
            <FontAwesomeIcon icon="fa-star" />
          </span>
          <span>4.8 out of 5</span>
        </div>
        <span style="margin-bottom: 10px">1678 customer ratings</span>

        <div :class="styles.card__ratings">
          <div v-for="review in reviews" :key="review.id" :class="styles['card__ratings--indiv']">
            <span> {{ review.text }} </span>
            <div :class="styles['custom-progress-bar']">
              <div :style="{ width: review.percent + '%' }" :class="styles['custom-progress-bar__progress']"></div>
            </div>
            <span> {{ review.percent }}% </span>
          </div>
        </div>
      </div>
    </div>

    <div :class="styles.review__card">
      <div :class="styles['review__pros--title']">Pros</div>
      <section v-for="pro in pros" :key="pro.id" :class="styles.check">
        <span>
          <FontAwesomeIcon icon="fa-check-circle" />
        </span>
        <span>
          {{ pro.text }}
        </span>
      </section>
      <div :class="styles['review__cons--title']">Cons</div>
      <section v-for="con in cons" :key="con.id" :class="styles.xmark">
        <span>
          <FontAwesomeIcon icon="fa-circle-xmark" />
        </span>
        <span>
          {{ con.text }}
        </span>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar, faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import useMessages from "@/composables/use-messages";

library.add(faStar, faCheckCircle, faCircleXmark);

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  name: "ReviewsComponent",
  setup() {
    const router = useRouter();
    const { pros, cons } = useMessages();

    const reviews = ref([
      { id: 1, text: "5", percent: 97 },
      { id: 2, text: "4", percent: 2 },
      { id: 3, text: "3", percent: 0 },
      { id: 4, text: "2", percent: 0 },
      { id: 5, text: "1", percent: 1 },
    ]);

    const goToDesignatedRoute = (route: string): void => {
      router.push(route);
    };

    return {
      reviews,
      pros,
      cons,
      goToDesignatedRoute,
    };
  },
});
</script>

<style module="styles" lang="scss">
.content {
  padding: 20px;
  width: 100%;
  max-height: 580px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  button {
    width: 300px !important;
  }
}

.card {
  width: 300px;
  height: 400px;
  background-color: #e7f0e6;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    width: 100%;
    span {
      font-size: 14px;
    }
  }

  &__ratings {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 15px;
    width: 100%;

    &--indiv {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
}
.custom-progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
  border-radius: 20px;
  margin: 0 auto;
  overflow: hidden;

  &__progress {
    border-radius: 20px;
    height: 100%;
    background-color: #9cd197;
  }
}
.review {
  &__card {
    width: 300px;
    height: 650px;
    overflow-y: scroll;
    padding: 20px;
    background-color: #e7f0e6;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    text-align: left;

    section {
      padding: 10px 0;
      border-bottom: 1px solid grey;
      width: 100%;
      &:nth-last-child(6) {
        border-bottom: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }

  &__pros {
    &--title {
      width: 100%;
      background-color: #9cd197;
      padding: 10px 0;
      border-radius: 5px;
      text-align: center;
      font-weight: bolder;
    }
  }
  &__cons {
    &--title {
      width: 100%;
      background-color: #f5605b;
      padding: 10px 0;
      border-radius: 5px;
      text-align: center;
      font-weight: bolder;
    }
  }
}
.check {
  display: flex;
  gap: 5px;
  svg {
    color: #9cd197;
  }
}
.xmark {
  display: flex;
  gap: 5px;
  svg {
    color: #f5605b;
  }
}
.stars {
  width: 200px;
  display: flex;
  justify-content: center;
  gap: 10px;
  background-color: #b5c4b3;
  border-radius: 20px;
  padding: 10px;
  svg {
    color: gold;
  }
}
</style>
