import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!
  const _component_GoBack = _resolveComponent("GoBack")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.styles.content)
  }, [
    (!_ctx.isListToggled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.styles.message__wrapper)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.styles.message__content),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.nextMessage && _ctx.nextMessage(...args)))
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
              return _withDirectives((_openBlock(), _createElementBlock("span", {
                key: message.id
              }, _toDisplayString(message.text), 1)), [
                [_vShow, _ctx.currentIndex === index]
              ])
            }), 128))
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass({[_ctx.styles.arrows]: true, [_ctx.styles.arrows__margin]: _ctx.currentIndex === 99})
          }, [
            _createVNode(_component_FontAwesomeIcon, {
              onClick: _ctx.prevMessage,
              icon: "fa-arrow-left"
            }, null, 8, ["onClick"]),
            _createVNode(_component_FontAwesomeIcon, {
              onClick: _ctx.nextMessage,
              icon: "fa-arrow-right"
            }, null, 8, ["onClick"])
          ], 2),
          (_ctx.currentIndex === 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.styles.tip)
              }, [
                _createVNode(_component_FontAwesomeIcon, { icon: "fa-info-circle" }),
                _createTextVNode(" Or tap the message to see the next one ")
              ], 2))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.styles.message__list)
        }, [
          _createElementVNode("ol", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
              return (_openBlock(), _createElementBlock("li", {
                key: message.id
              }, _toDisplayString(message.text), 1))
            }), 128))
          ])
        ], 2)),
    _createVNode(_component_GoBack, {
      onToggleList: _ctx.toggleList,
      class: _normalizeClass(_ctx.styles.buttons)
    }, null, 8, ["onToggleList", "class"])
  ], 2))
}