import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../../assets/KoalaAndSloth.png'


const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FontAwesomeIcon = _resolveComponent("FontAwesomeIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.styles.wrapper)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({ [_ctx.styles.flex]: true, [_ctx.styles['flex__sidebar-open']]: _ctx.isSidebarOpen })
    }, [
      _createVNode(_component_FontAwesomeIcon, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleSidebar'))),
        icon: "fa-bars"
      }),
      (!_ctx.isSidebarOpen)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.routeTitle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args))),
        alt: "home",
        src: _imports_0
      })
    ], 2)
  ], 2))
}