<template>
  <div :class="styles.wrapper">
    <div :class="{ [styles.flex]: true, [styles['flex__sidebar-open']]: isSidebarOpen }">
      <FontAwesomeIcon @click="$emit('toggleSidebar')" icon="fa-bars" />
      <h4 v-if="!isSidebarOpen">{{ routeTitle }}</h4>
      <img @click="goHome" alt="home" src="../../assets/KoalaAndSloth.png" />
    </div>
  </div>
</template>

<script lang="ts">
import { RoutingRouteEnum } from "@/routing/route.enum";
import { defineComponent, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBars } from "@fortawesome/free-solid-svg-icons";

library.add(faBars);

export default defineComponent({
  components: {
    FontAwesomeIcon,
  },
  name: "HeaderComponent",
  props: {
    isSidebarOpen: Boolean,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const routeTitle = ref<string>("");

    const goHome = (): void => {
      router.push(RoutingRouteEnum.home);
    };

    watch(
      () => route.meta.title,
      (newTitle) => {
        routeTitle.value = (newTitle as string) || "";
      },
      { immediate: true }
    );

    return {
      goHome,
      routeTitle,
    };
  },
});
</script>
<style module="styles" lang="scss">
.wrapper {
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  div {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  img {
    width: 50px;
    max-width: 50px;
  }

  &:hover {
    cursor: pointer;
  }

  span {
    color: #2f4858;
  }

  svg {
    color: #88aea8;
  }

  &__list {
    width: 100%;
    justify-content: space-around;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  height: 60px;

  svg {
    margin-left: 15px;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
  }

  &__sidebar-open {
    svg {
      margin-left: 310px;
      @media (max-width: 500px) {
        margin-left: 295px;
      }
    }
  }
}
</style>
