<template>
  <div :class="styles.content">
    <div style="margin-bottom: 10px;">JUL 11, 2019</div>
    <div v-for="(message, index) in messages" :key="message.id" :class="{
      [styles.message]: true, [styles.animated]: message.displayed,
      [styles[`message__${index < 2 ? 'sender' : 'receiver'}`]]: true,
      [styles['message__sender-1']]: index === 0,
      [styles['message__sender-2']]: index === 1,
      [styles['message__receiver-1']]: index === 2,
      [styles['message__receiver-2']]: index === 3
    }">
      {{ message.text }}
    </div>
    <div :class="styles['writing-container']">
      <div :class="styles.writing" v-if="showWriter">
        <div :class="{ [styles.writing__dot]: true, [styles['writing__dot--left']]: true }"></div>
        <div :class="{ [styles.writing__dot]: true, [styles['writing__dot--center']]: true }"></div>
        <div :class="{ [styles.writing__dot]: true, [styles['writing__dot--right']]: true }"></div>
      </div>
      <div :class="styles.replay" v-if="showReplay">
        <a href="#" @click="start">replay</a>
      </div>
    </div>

    <div v-if="showRest" :class="styles.rest">
      <div>Who knew this would be the start of <span :class="styles.rest__link" @click="goToEverything">everything?</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { RoutingRouteEnum } from '@/routing/route.enum';

export default defineComponent({
  name: 'BirthdayJourney',
  setup() {
    const router = useRouter();
    const messages = ref([
      { id: 1, text: "Hey there", displayed: false },
      { id: 2, text: "I love your style", displayed: false },
      { id: 3, text: "Hey", displayed: false },
      { id: 4, text: "Thank you!", displayed: false },
    ]);

    const showWriter = ref(true);
    const showReplay = ref(false);
    const showRest = ref(false);

    const start = (): void => {
      const messageCount = messages.value.length;
      showWriter.value = true;
      showReplay.value = false;

      messages.value.forEach((message) => {
        message.displayed = false;
      });

      const messageElements = document.querySelectorAll('.message');
      messageElements.forEach((element) => {
        element.classList.remove('animated');
      });

      const writingContainer = document.querySelector('.writing');
      if (showWriter.value) {
        writingContainer?.classList.remove('writing');
      }

      let totalDuration = 0;

      messages.value.forEach((message, index) => {
        setTimeout(() => {
          showMessage(index);
          if (index === messageCount - 1) {
            setTimeout(() => {
              showWriter.value = false;
              showReplay.value = true;

              totalDuration += (messageCount - 1) * 1000;

              setTimeout(() => {
                showTheRest();
              }, 400);
            }, 300);
          }
        }, totalDuration + (index + 1) * 1000);
      });
    };

    const goToEverything = (): void => {
      router.push(RoutingRouteEnum.everything);
    };

    const doBounce = (element: HTMLElement, times: number, distance: string, speed: number): void => {
      for (let i = 0; i < times; i++) {
        (element.animate as any)({ marginTop: '-=' + distance }, speed).animate({ marginTop: '+=' + distance }, speed);
      }
    };

    const showMessage = (index: number): void => {
      const message = messages.value[index];
      message.displayed = true;
      const element = document.getElementById(`message-${message.id}`);
      if (element) {
        doBounce(element, 1, '4px', 150);
      }
    };

    const showTheRest = (): void => {
      showRest.value = true;
    };

    onMounted(() => {
      start();
    });

    return {
      messages,
      showWriter,
      showReplay,
      showRest,
      start,
      goToEverything,
    };
  },
});
</script>

<style module="styles" lang="scss">
.content {
  max-width: 500px;
  margin: auto;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin-block-start: -50px;
}

.message {
  display: none;
  margin-bottom: 2px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  padding: 10px 17px;
  color: #f5f5f5;

  &__sender {
    background: #3797f0;
    align-self: end;
  }

  &__sender-1 {
    border-bottom-right-radius: 0;
  }

  &__sender-2 {
    border-top-right-radius: 0;
  }

  &__receiver {
    background-color: #424242;
    align-self: start;
  }

  &__receiver-1 {
    border-bottom-left-radius: 0;
  }

  &__receiver-2 {
    border-top-left-radius: 0;
  }
}

.replay {
  margin: 30px 0 10px 0;
  background-color: #dff5dc;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  border-radius: 8px;
  padding: 10px 17px;
  width: 100px;

  a {
    color: #2F4858;
    text-decoration: none;
  }
}

.animated {
  display: inline-block;
}

.writing-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.writing {
  display: block;
  height: 38px;
  width: 90px;
  background-color: #424242;
  border-radius: 25px;
  margin: 10px 0;

  &__dot {
    height: 15px;
    width: 15px;
    background-color: #aaa;
    opacity: 0.3;
    border-radius: 50%;
    position: relative;
    -webkit-animation: dots .9s ease-in-out infinite;
    animation: dots .9s ease-in-out infinite;

    &--left {
      top: -7px;
      margin-left: 17px;
    }

    &--center {
      top: -22px;
      left: 37px;
      -webkit-animation-delay: 0.3s;
    }

    &--right {
      top: -37px;
      left: 57px;
      -webkit-animation-delay: 0.6s;
    }
  }
}

.writing::before {
  content: '';
  border: solid 20px;
  border-color: transparent transparent #424242;
  position: relative;
  top: 0px;
  left: -10px;
}

.rest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  opacity: 0;
  animation: fadeIn 1s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &__link {
    font-style: italic;
    font-weight: bold;
    color: #88AEA8;

    &:hover {
      cursor: pointer;
    }
  }
}

@-webkit-keyframes dots {
  0% {
    opacity: 1;
  }
}

@keyframes dots {
  0% {
    opacity: 1;
  }
}
</style>