<template>
  <div :class="styles.content">
    <div v-if="!isListToggled" :class="styles.message__wrapper">
      <div :class="styles.message__content" @click="nextMessage">
        <span v-for="(message, index) in messages" :key="message.id" v-show="currentIndex === index">
          {{ message.text }}
        </span>
      </div>
      <div :class="{[styles.arrows]: true, [styles.arrows__margin]: currentIndex === 99}">
        <FontAwesomeIcon @click="prevMessage" icon="fa-arrow-left" />
        <FontAwesomeIcon @click="nextMessage" icon="fa-arrow-right" />
      </div>
      <div :class="styles.tip" v-if="currentIndex === 0">
        <FontAwesomeIcon icon="fa-info-circle" />
        Or tap the message to see the next one
      </div>
    </div>
    <div v-else :class="styles.message__list">
      <ol>
        <li v-for="message in messages" :key="message.id">{{ message.text }}</li>
      </ol>
    </div>
    <GoBack @toggleList="toggleList" :class="styles.buttons" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import GoBack from "../GoBack/GoBack.vue";
import useMessages from "../../../composables/use-messages";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

library.add(faInfoCircle);

export default defineComponent({
  components: {
    GoBack,
    FontAwesomeIcon,
  },
  name: "LoveComponent",
  setup() {
    const { messages } = useMessages();
    const isListToggled = ref(false);
    const currentIndex = ref(0);

    const prevMessage = (): void => {
      currentIndex.value = (currentIndex.value - 1 + messages.value.length) % messages.value.length;
    };

    const nextMessage = (): void => {
      currentIndex.value = (currentIndex.value + 1) % messages.value.length;
    };

    const toggleList = () => {
      isListToggled.value = !isListToggled.value;
    };

    return {
      messages,
      currentIndex,
      isListToggled,
      nextMessage,
      prevMessage,
      toggleList,
    };
  },
});
</script>

<style module="styles" lang="scss">
.content {
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  margin-block-start: -50px;
}

.tip {
  display: flex;
  margin-top: 10px;
  gap: 4px;
  align-items: center;
  font-size: 10px;
  justify-content: center;
  
  svg {
    color: #88AEA8;
  }
}

.toggleList {
  font-style: italic;
  font-weight: bold;
  color: #88aea8;

  &:hover {
    cursor: pointer;
  }
}

.buttons {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: 20px;
  z-index: 1000;
}

.message__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  max-height: 400px;
  overflow: scroll;
  width: 100%;
  border-radius: 8px;
}

.message__wrapper {
  width: 100%;
}
.arrows{
  display: flex;
  justify-content: center;
  gap: 30px;
  color: #88AEA8;

  &__margin {
    margin-top: 20px;
  }
}
.message__list {
  font-size: 14px;
  text-align: start;
  max-height: 400px;
  width: 100%;
  overflow: scroll;
}

button {
  background-color: #dff5dc;
  color: #2f4858;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 100%;
  height: 50px;
  margin-top: 30px;

  &:hover {
    cursor: pointer;
  }
}

li {
  margin-bottom: 8px;

  &::marker {
    color: #648b8f;
    font-weight: bold;
  }
}

h1 {
  margin: 30px 0 0;
  color: #2f4858;
  font-family: "Montserrat", sans-serif;
}

a {
  color: #42b983;
}
</style>
