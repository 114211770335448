import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { RoutingRouteEnum } from "./route.enum";
import JumMain from "../components/JumMain.vue";
import BirthdayJourney from "../components/JourneyComponent/First/Birthday.vue";
import EverythingComponent from "../components/JourneyComponent/Everything/Everything.vue";
import LetterComponent from "../components/JourneyComponent/Everything/Letter.vue";
import LoveComponent from "../components/JourneyComponent/Everything/Love.vue";
import PlaylistComponent from "../components/JourneyComponent/Everything/Playlist.vue";
import ReviewsComponent from "../components/JourneyComponent/Everything/Reviews.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: RoutingRouteEnum.home },
  },
  {
    path: "/home",
    meta: {
      title: "Home (you)",
    },
    name: RoutingRouteEnum.home,
    component: JumMain,
  },
  {
    path: "/journey",
    meta: {
      title: "Beginnings",
    },
    name: RoutingRouteEnum.birthday,
    component: BirthdayJourney,
  },
  {
    path: "/everything",
    meta: {
      title: "Everything (you)",
    },
    name: RoutingRouteEnum.everything,
    component: EverythingComponent,
  },
  {
    path: "/letter",
    meta: {
      title: "A Letter for You",
    },
    name: RoutingRouteEnum.letter,
    component: LetterComponent,
  },
  {
    path: "/love",
    meta: {
      title: "I love about you...",
    },
    name: RoutingRouteEnum.love,
    component: LoveComponent,
  },
  {
    path: "/playlist",
    meta: {
      title: "A Playlist for You",
    },
    name: RoutingRouteEnum.playlist,
    component: PlaylistComponent,
  },
  {
    path: "/reviews",
    meta: {
      title: "Reviews",
    },
    name: RoutingRouteEnum.reviews,
    component: ReviewsComponent,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
